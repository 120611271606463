import React from 'react';

import './App.css';
import airImage from './images/Wind Minimal.png';
import fireImage from './images/Fire Minimal.png';
import waterImage from './images/Water Minimal.png';
import earthImage from './images/Earth Minimal.png';
import chris from './images/Chris.jpeg';
import carson from './images/Carson.jpeg';
import elementVideo from './videos/ElementVideo1.0.mp4';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">Element</h1>
        <p className="App-statement-title">Vision Statement</p>
        <div className="App-statement-container">
          <p className="App-statement">
            At Element, we're bringing fitness enthusiasts together. Our social media platform lets you effortlessly connect, share your fitness journey, and find new adventures.
          </p>
          <p className="App-statement">
            We're committed to an active lifestyle and genuine connections, making Element a hub for personal growth and community building.
          </p>
          <p className="App-statement">
            Join us in sparking the spirit of adventure and passion for fitness worldwide.
          </p>
          <p className="App-statement-question">
            Where will your next adventure be?
          </p>
        </div>
      </header>

      
      
      <div className="App-content">
        <div className="box">
          <div className="box-inner">
            <div className="box-front">
              <img src={fireImage} alt="Fire Element" />
            </div>
            <div className="box-back">
              <h2>Fire</h2>
              <p>Ignite your passion for indoor fitness pursuits and push your limits in the gym with activities like weightlifting, powerlifting, and CrossFit.</p>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="box-inner">
            <div className="box-front">
              <img src={earthImage} alt="Earth Element" />
            </div>
            <div className="box-back">
              <h2>Earth</h2>
              <p>Explore the great outdoors and challenge yourself with adventures like hiking, mountain biking, camping, and rock climbing.</p>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="box-inner">
            <div className="box-front">
              <img src={waterImage} alt="Water Element" />
            </div>
            <div className="box-back">
              <h2>Water</h2>
              <p>Dive into the refreshing world of water-based activities, from swimming and surfing to kayaking and paddleboarding, and experience the fluidity of fitness.</p>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="box-inner">
            <div className="box-front">
              <img src={airImage} alt="Air Element" />
            </div>
            <div className="box-back">
              <h2>Wind</h2>
              <p>Embrace the freedom of movement and exhilaration of outdoor sports like running, cycling, skateboarding, and paragliding, as you harness the power of the wind.</p>
            </div>
          </div>
        </div> 
      </div>

      <div className="video-container">
          <video className="centered-video" width={1050} height={600} controls>
            <source src={elementVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

      <div className="info-sections">
            <section className="key-features">
                <h2>Key Features</h2>
                <ul>
                    <li>Post Videos and Images: Share your adventures through multimedia posts.</li>
                    <li>Inspirational Content: Get inspired by trending adventures and featured posts.</li>
                    <li>Location-Based Exploration: Discover local adventures and hidden trails.</li>
                    <li>Follow and Connect: Build your network and engage with the community.</li>
                    <li>Connect Fitness Apps: Integrate with popular fitness tracking apps.</li>
                    <li>Advanced Search: Filter posts by activities, locations, or hashtags.</li>
                    <li>Tailored Content: Receive recommendations based on your interests.</li>
                    <li>Activity Feed: Get real-time updates from other adventurers.</li>
                    <li>Direct Messaging: Communicate directly with other users.</li>
                    <li>Explore: Find posts tailored to specific adventure.</li>
                </ul>
            </section>

            <section className="how-it-works">
                <h2>How It Works</h2>
        
                <ul>
                    <li>Create a profile and set your preferred adventures.</li>
                    <li>Connect with others and join adventures based on your interests.</li>
                    <li>Track your activities, share your progress, and inspire other adventurers.</li>
                    <li>Stay motivated and inspired by engaging with our supportive community.</li>
                    <li>Discover the world.</li>
                </ul>
            </section>

            <section className="benefits">
                <h2>Benefits</h2>
                <ul>
                    <li>Connect with a community of fitness enthusiasts</li>
                    <li>Stay motivated and accountable towards your goals</li>
                    <li>Discover new activities and adventures</li>
                    <li>Share your progress and inspire others</li>
                    <li>Connect with your tracking apps.</li>
                </ul>
            </section>
        </div>


      <section className="team">
        <h2>Meet Our Team</h2>
        <div className="team-members">
          <div className="team-member">
            <img src={chris} alt="Team Member 1" />
            <h3>C.M. Schmidt</h3>
            <p>CEO & Co-founder</p>
          </div>
          <div className="team-member">
            <img src={carson} alt="Team Member 2" />
            <h3>Carson Degele</h3>
            <p>Android Developer & Co-founder</p>
          </div>
        </div>
      </section>


      <section className="contact">
        <h2>Contact Us</h2>
        <p>If you have any questions or inquiries, feel free to reach out to us:</p>
        
          <p>elemental.cm.dev@gmail.com</p>
          <p>Kalispell, MT</p>
      
      </section>


    </div>
  );
}

export default App;
